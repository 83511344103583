import { differenceInMilliseconds } from "date-fns";
import { useEffect, useState } from "react";

import { delay } from "#src/utils/delay.ts";
import { durationInMilliseconds } from "#src/utils/duration.ts";
import { useInterval } from "src/hooks/useInterval.tsx";

const STATUS_URL = "/monitoring/ping";

const POLLING_TIMEOUT_MS = durationInMilliseconds({ seconds: 10 });
const POLLING_INTERVAL_MS = durationInMilliseconds({ seconds: 30 });
const SLOW_CONNECTION_THRESHOLD_MS = durationInMilliseconds({ seconds: 2 });

export const InternetConnectionStatus = {
  OFFLINE: "OFFLINE",
  ONLINE: "ONLINE",
  SLOW: "SLOW",
} as const;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type InternetConnectionStatus = EnumFrom<typeof InternetConnectionStatus>;

export function useInternetConnectionStatus() {
  const [status, setStatus] = useState<InternetConnectionStatus>(
    InternetConnectionStatus.ONLINE
  );

  // Listen for online/offline events to update the status
  useEffect(() => {
    const handleOnline = () => {
      setStatus(prev =>
        prev === InternetConnectionStatus.OFFLINE
          ? InternetConnectionStatus.ONLINE
          : prev
      );
    };

    const handleOffline = () => {
      setStatus(InternetConnectionStatus.OFFLINE);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Poll the status to update the status
  useInterval(async () => {
    try {
      const start = new Date();
      const result = await Promise.race([
        fetch(STATUS_URL),
        delay(POLLING_TIMEOUT_MS),
      ]);
      const end = new Date();

      if (!result) {
        setStatus(InternetConnectionStatus.OFFLINE);
        return;
      }

      const diffInMs = differenceInMilliseconds(end, start);

      if (diffInMs > SLOW_CONNECTION_THRESHOLD_MS) {
        setStatus(InternetConnectionStatus.SLOW);
      } else {
        setStatus(InternetConnectionStatus.ONLINE);
      }
    } catch {
      setStatus(InternetConnectionStatus.OFFLINE);
    }
  }, POLLING_INTERVAL_MS);

  return status;
}
